import React from "react";
import { graphql } from "gatsby";
import withLayout from "../components/withLayout";
import {
  HR,
  GuideContainer,
  WhiteContentBox,
  PageHeader,
  PageContainer
} from "../components/common";
import PropTypes from "prop-types";
import TableOfContents from "../components/TableOfContents";
import { PoseGroup } from "react-pose";
import { Link } from "gatsby";
import styled from "styled-components";
// Airtable content is translated so we can use strings directly.

const StyledLi = styled.li`
  margin: 16px 0px;
  a {
    font-weight: 600;
    font-size: 20px;
    color: #b71c1c;
  }
`;

const SectionTemplate = ({
  content,
  toc_header,
  toc_button,
  topic_overline,
  header,
  TOC
}) => {
  return (
    <>
      <PageContainer>
        <PageHeader>
          <h1>{header.data.Text}</h1>
        </PageHeader>
      </PageContainer>
      <GuideContainer>
        <TableOfContents
          header={toc_header.data.Text}
          overline={header.data.Text}
          TOC={TOC}
          currentPath={content.Path}
          buttonText={toc_button.data.Text}
        />
        <PoseGroup animateOnMount={true}>
          <WhiteContentBox key="boxkey">
            <h6>{topic_overline.data.Text}</h6>
            <h2 style={{ marginTop: 0 }}>{content.Title}</h2>
            <HR />
            <section
              className="markdown-content"
              dangerouslySetInnerHTML={{ __html: content.Markdown }}
            />
            {content.Pages && (
              <ul>
                {content.Pages.map(page => (
                  <StyledLi key={page.Path}>
                    <Link to={page.Path}>{page.Title}</Link>
                  </StyledLi>
                ))}
              </ul>
            )}
          </WhiteContentBox>
        </PoseGroup>
      </GuideContainer>
    </>
  );
};

export default withLayout(SectionTemplate);

SectionTemplate.propTypes = {
  content: PropTypes.shape({ Title: PropTypes.string.isRequired }).isRequired,
  toc_header: PropTypes.object.isRequired,
  toc_button: PropTypes.object.isRequired,
  topic_overline: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  TOC: PropTypes.object.isRequired
};

export const query = graphql`
  query getGuideSection($Path: String!, $Locale: String!) {
    topic_overline: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "topic_overline" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    toc_header: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "toc_header" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    toc_button: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "toc_button" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    header: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "header" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    TOC: allAirtable(
      filter: {
        table: { eq: "Guide Sections" }
        data: { Locale: { eq: $Locale } }
      }
      sort: { fields: data___Position, order: ASC }
    ) {
      ...TOC
    }
    content: allAirtable(filter: { data: { Path: { eq: $Path } } }) {
      edges {
        node {
          data {
            Path
            Title
            metaDescription
            siteTitle
            Markdown {
              childMarkdownRemark {
                html
              }
            }
            Pages {
              data {
                Title
                Path
              }
            }
          }
        }
      }
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
